// import 'core-js/stable';
import 'regenerator-runtime/runtime';
import axios from 'axios';

// Copy all static images to the output folder and reference them with
// the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)

// Copy all static icons to the output folder and reference them with
// the inline_svg_pack_tag helper in views (e.g <%= inline_svg_pack_tag 'media/icons/rails.svg' %>)

// Copy all static images to the output folder and reference them with
// the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)

// Copy all static icons to the output folder and reference them with
// the inline_svg_pack_tag helper in views (e.g <%= inline_svg_pack_tag 'media/icons/rails.svg' %>)


// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

const token = document.querySelector('meta[name="csrf-token"]');
if (token) axios.defaults.headers.common['X-CSRF-Token'] = token.getAttribute('content');

import './vue-app';

import '../stylesheets/tailwind.css';

import Turbolinks from 'turbolinks';

Turbolinks.start();
Turbolinks.setProgressBarDelay(600);
